import React from 'react'

const SocioCapitalism = () => {
    return (
        <div className='p-10' >
            <center>
                <h1 className='text-4xl mb-4' >Socio Capitalism</h1>
                <label className='text-base italic' >Yunus Parvez Khan</label>
                <div className='p-6 flex flex-col' >
                    <span className='text-red-500'>Content Removed for quick correction</span>
                    <span>November 29th, 2024</span>
                </div>
            </center>
        </div>
    )
}

export default SocioCapitalism